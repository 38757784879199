//import { Image } from "react-grid-gallery";

export const images = [
  {
    src: "./assets/images/animal_rescue_and_treatment/sandha/sandha_(1).jpg",
    original: "./assets/images/animal_rescue_and_treatment/sandha/sandha_(1).jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Rescue", title: "Ox" },
      { value: "Accident", title: "Ox" },
    ],
    caption: "Treatment of OX after accident",
  },
  {
    src: "./assets/images/animal_rescue_and_treatment/monkey/monkey_ (4).jpg",
    original: "./assets/images/animal_rescue_and_treatment/monkey/monkey_ (4).jpg",
    width: 320,
    height: 212,
    caption: "Saving electrocuted monkey",
  },
  {
    src: "./assets/images/animal_rescue_and_treatment/dog/dog_rescue_01.jpg",
    original: "./assets/images/animal_rescue_and_treatment/dog/dog_rescue_01.jpg",
    width: 320,
    height: 212,
    caption: "Dog Rescue",
  },
  {
    src: "./assets/images/event/fitness_day/FitnessDay_01.jpg",
    original: "./assets/images/event/fitness_day/FitnessDay_01.jpg",
    width: 320,
    height: 212,
    tags: [{ value: "Fitness Day", title: "Village" }],
    caption: "Fitness Day",
  },
  {
    src: "./assets/images/event/INDEPENDENCE_DAY_2022/independenceDay.jpg",
    original: "./assets/images/event/INDEPENDENCE_DAY_2022/independenceDay.jpg",
    width: 320,
    height: 213,
    caption: "Celebrating Independence Day",
  },
  {
    src: "./assets/images/health_awarness/health_day/healthDay_01.jpeg",
    original: "./assets/images/health_awarness/health_day/healthDay_01.jpeg",
    width: 320,
    height: 213,
    caption: "Health Day",
  },
  {
    src: "./assets/images/health_awarness/yoga/yoga_01.jpg",
    original: "./assets/images/health_awarness/yoga/yoga_01.jpg",
    width: 320,
    height: 215,
    tags: [{ value: "Yoga", title: "People gathered to learn Yoga." }],
    caption: "People gathered to learn Yoga.",
  }
];
