import React, { useState } from 'react'
import { Tweet, EmbeddedTweet } from 'react-tweet';

function Social() {

    const [tweetIds, setTweetIds] = useState(['1745594280742449284', '1777551214991049198','1777212883421495296'])
    return (
        <div>
            {tweetIds.map((item, index) => <div key={'tweet_'+index} className="dark">
                <Tweet id={item} />
            </div>)}
        </div>


    )
}

export default Social;